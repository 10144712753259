<template lang="pug">
  v-tabs(v-model="activeTab" background-color="transparent").cd-sidebar-tabs
    v-tab(
      v-for="tab in tabs"
      :key="tab.name"
      @click="switchTab(tab.name)"
    )
      div
        div {{ tab.label }}
</template>

<script>
import {COURSE_DAY_SIDEBAR_TABS, TABS_QUERY, COURSE_DAY_FILTERS} from '../../core/courseDay-const'

export default {
  name: 'CourseDaySidebarTabs',

  data: () => ({
    activeTab: 0,
    COURSE_DAY_SIDEBAR_TABS,
    TABS_QUERY
  }),

  computed: {
    tabs() {
      return [
        {
          name: COURSE_DAY_SIDEBAR_TABS.FUTURE,
          label: 'Future'
        },
        {
          name: COURSE_DAY_SIDEBAR_TABS.PAST,
          label: 'Past'
        },
      ]
    }
  },

  created() {
    this.setTabByQuery()
  },

  methods: {
    async switchTab(name) {
      if (this.$route.query[TABS_QUERY] === name) return
      await this.$router.replace({
        query: {
          ...this.$route.query,
          [TABS_QUERY]: name,
          [COURSE_DAY_FILTERS.DATE]: undefined,
          page_sidebar: 1
        }
      })
      this.$emit('click:tab', name)
    },

    setTabByQuery() {
      let name = this.$route.query[TABS_QUERY]
      let tabIndex = this.tabs.findIndex(tab => tab.name === name)
      if (tabIndex < 0) return
      this.activeTab = tabIndex
    }
  },

  watch: {
    '$route.query.tab_name': function (newVal, oldVal) {
      this.setTabByQuery()
    }
  }
}
</script>

<style lang="scss">
.cd-sidebar-tabs {
  .v-tabs-bar {
    height: 40px;
  }

  .v-tab {
    width: 100%;
  }
}
</style>
